import { apiAgencies } from '@/api/'
import store from '@/store'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAgencies(ctx, params) {
      return apiAgencies.fetchAgencies(params)
    },

    fetchListReg(ctx, params) {
      return apiAgencies.fetchReglist(params)
    },

    getAgenciesById(ctx, { id }) {
      return apiAgencies.getAgenciesById(id)
    },

    addAgencies(ctx, agenciesData) {
      return apiAgencies.addAgencies(agenciesData)
    },

    validateEmail(ctx, emailAddress) {
      return apiAgencies.validateEmail(emailAddress)
    },

    updateAgencies(ctx, agenciesData) {
      return apiAgencies.updateAgencies(
        agenciesData.id,
        agenciesData.dataToUpdate,
      )
    },

    bulkDeleteAgencies(cxt, id) {
      return apiAgencies.bulkDeleteAgencies(id)
    },

    bulkDeactiveAgencies(cxt, id) {
      return apiAgencies.bulkDeactiveAgencies(id)
    },

    updateActive(ctx, id) {
      return apiAgencies.updateActive(id)
    },

    updateDeactive(ctx, id) {
      return apiAgencies.updateDeactive(id)
    },

    exportAgencies(ctx, payload, fileName = 'Agencies-List') {
      return apiAgencies.exportAgencies(payload, fileName)
    },

    exportAgenciesTemplate(ctx, fileName = 'Agencies-Template') {
      return apiAgencies.exportAgenciesTemplate(fileName)
    },

    importAgencies(ctx, payload) {
      return apiAgencies.bulkInsert(payload)
    },

    assignManagers(ctx, { id, payload }) {
      return apiAgencies.assignManagers(id, payload)
    },

    changeNote(ctx, { id, payload }) {
      return apiAgencies.changeNote(id, payload)
    },

    changeSocialNetwork(ctx, { id, payload }) {
      return apiAgencies.changeSocialNetwork(id, payload)
    },

    updateHideFeeConfig(ctx, { id }) {
      return apiAgencies.updateHideFeeConfig(id)
    },

    SetLowFareTracking(ctx, { id }) {
      return apiAgencies.SetLowFareTracking(id)
    },

    updateAgenciesFeeConfig(ctx, { id }) {
      return apiAgencies.updateAgenciesFeeConfig(id)
    },

    updateSendMailAirline(ctx, { id, payload }) {
      return apiAgencies.updateSendMailAirline(id, payload)
    },

    updateSendPhoneAirline(ctx, { id, payload }) {
      return apiAgencies.updateSendPhoneAirline(id, payload)
    },

    updateEnableQuickName(ctx, { id }) {
      return apiAgencies.updateEnableQuickName(id)
    },

    updateEnableRebook(ctx, { id }) {
      return apiAgencies.updateEnableRebook(id)
    },
    updateEnableRetail(ctx, { id }) {
      return apiAgencies.updateEnableRetail(id)
    },

    updateEnableInvoice(ctx, { id }) {
      return apiAgencies.updateEnableInvoice(id)
    },

    updateEnableInvoiceRequest(ctx, { id }) {
      return apiAgencies.updateEnableInvoiceRequest(id)
    },

    updateEnableTour(ctx, { id }) {
      return apiAgencies.updateEnableTour(id)
    },

    updateEnableTrain(ctx, { id }) {
      return apiAgencies.updateEnableTrain(id)
    },

    updateEnableRenameOnMarketPlace(ctx, { id }) {
      return apiAgencies.updateEnableRenameOnMarketPlace(id)
    },
    updateLimitTimeSearch(ctx, { id, payload }) {
      return apiAgencies.updateLimitTimeSearch(id, payload)
    },

    updateLimitPaxBooking(ctx, { id, payload }) {
      return apiAgencies.updateLimitPaxBooking(id, payload)
    },

    resetBalance(ctx, { id, payload }) {
      return apiAgencies.resetBalance(id, payload)
    },

    // Cho phép book đoàn
    enableBookingGroup(ctx, id) {
      return apiAgencies.enableBookingGroup(id)
    },

    // cho phép nhân viên nhận mail hãng
    enableSendEmployeeMailAirline(ctx, id) {
      return apiAgencies.enableSendEmployeeMailAirline(id)
    },

    // company
    async createCompany(ctx, { id, payload }) {
      await apiAgencies.createCompany(id, payload)
      const agencyIdLoggedIn = store.getters['userStore/getAgencyDataId']
      if (id === agencyIdLoggedIn) store.dispatch('userStore/fetchMeData')
    },

    async updateCompany(ctx, { id, payload }) {
      await apiAgencies.updateCompany(id, payload)
      const agencyIdLoggedIn = store.getters['userStore/getAgencyDataId']
      if (id === agencyIdLoggedIn) store.dispatch('userStore/fetchMeData')
    },

    async toggleActiveCompany(ctx, { id, payload }) {
      await apiAgencies.toggleActiveCompany(id, payload)
      const agencyIdLoggedIn = store.getters['userStore/getAgencyDataId']
      if (id === agencyIdLoggedIn) store.dispatch('userStore/fetchMeData')
    },

    // web config
    async createWebConfig(ctx, { id, payload }) {
      await apiAgencies.createWebConfig(id, payload)
    },

    async updateWebConfig(ctx, { id, payload }) {
      await apiAgencies.updateWebConfig(id, payload)
    },

    async sendEmailTest(ctx, { payload }) {
      await apiAgencies.sendEmailTest(payload)
    },
  },
}
