import { ref, watch, computed } from '@vue/composition-api'
import debounce from 'lodash/debounce'

import api from '@/api'
import store from '@/store'
import { sizePerPageOptions } from '@/constants/selectOptions'
import { useRouter } from '@/@core/utils/utils'

import useToast from '@useToast'

export default function useAgenciessHandle() {
  const { toastError, toastSuccess } = useToast()
  const refAgenciesListTable = ref(null)
  const refRegListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { label: 'checkbox', key: 'checkbox', sortable: false },
    { label: 'agencyCode', key: 'agencyCode', sortable: false },
    { label: 'agencyInformation', key: 'information', sortable: false },
    { label: 'accountsPayableTemp', key: 'balance', sortable: false },
    // { label: 'manager', key: 'manager', sortable: false },
    { label: 'status', key: 'status', sortable: false },
    { label: 'agencyFee', key: 'agencyFee', sortable: false },
    { label: 'activated', key: 'isActive', sortable: false },
    { label: 'retail', key: 'Retail', sortable: false },
    { label: 'enableInvoice', key: 'enableInvoice', sortable: false },
    { label: 'enableInvoiceRequest', key: 'enableInvoiceRequest', sortable: false },
    { label: 'enableTour', key: 'enableTour', sortable: false },
    { label: 'enableTrain', key: 'enableTrain', sortable: false },
    { label: 'created', key: 'createdAt', sortable: true },
    { label: 'updated', key: 'updatedAt', sortable: true },

    // { label: 'taxCode', key: 'taxCode', sortable: false },
    // { label: 'e Contract', key: 'eContract', sortable: false },
    // { label: 'agency Name', key: 'agencyName', sortable: true },
    // { label: 'agency Owner', key: 'agencyOwner', sortable: true },
    // { label: 'account Id', key: 'accountId', sortable: true },
    // { label: 'Action', key: 'action', sortable: false },
  ]

  const tableColumnsReg = [
    { label: 'id', key: 'id', sortable: false },
    { label: 'Trạng thái', key: 'status', sortable: false },
    { label: 'Tên đại diện', key: 'name', sortable: false },
    { label: 'Email', key: 'email', sortable: false },
    { label: 'Số điện thoại', key: 'phone', sortable: false },
    { label: 'Tên đại lý', key: 'agencyName', sortable: true },
    { label: 'Địa chỉ', key: 'agencyAddress', sortable: false },
    { label: 'Thời gian tạo', key: 'createdAt', sortable: true },
    { label: 'Thời gian sửa', key: 'updatedAt', sortable: true },
    { label: 'Action', key: 'action', sortable: false },

  ]

  const tableEmployees = [
    { label: 'no', key: 'no' },
    { label: 'empCode', key: 'employeeCode' },
    { label: 'empInfo', key: 'information' },
    { label: 'tabInfo', key: 'account' },
    { label: 'created', key: 'createdAt', sortable: true },
    { label: 'updated', key: 'updatedAt', sortable: true },
    { label: 'status', key: 'isActive' },
  ]
  const sizePerPage = ref(40)
  const totalAgencies = ref(0)
  const totalListRegister = ref(0)

  const currentPage = ref(1)
  const idsPage = ref([])

  const sortBy = ref(null) // createdAt || updatedAt
  const isSortDirDesc = ref(true) //  ASC || DESC

  // Filter
  const searchTextFilter = ref(null)
  const statusActiveFilter = ref(true)
  const statusAgencyFilter = ref(null)
  const agencyManagerFilter = ref(null)
  const isWarningOverPercentage = ref(false)

  const emailDuplicate = ref(null)

  // Loading
  const loading = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refAgenciesListTable.value ? refAgenciesListTable.value.localItems.length : 0
    return {
      from: sizePerPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: sizePerPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalAgencies.value,
    }
  })

  const dataMetaRegister = computed(() => {
    const localItemsCount = refRegListTable.value ? refRegListTable.value.localItems.length : 0
    return {
      from: sizePerPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: sizePerPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalListRegister.value,
    }
  })

  // Empty filter
  const isEmptyFilter = computed(
    () => !!(
      statusActiveFilter.value === null
        && statusAgencyFilter.value === null
        && agencyManagerFilter.value === null
    ),
  )

  const refetchData = () => {
    if (refAgenciesListTable.value) {
      refAgenciesListTable.value.refresh()
    }
  }

  const refetchDataReg = () => {
    refRegListTable.value.refresh()
  }

  const validateEmail = email => {
    store
      .dispatch('app-agencies/validateEmail', { emailAddresses: [email] })
      .then(e => {
        emailDuplicate.value = e.failedItems.length
          ? e.failedItems[0].message
          : ''
      })
      .catch(error => {
        console.error({ error })
      })
  }

  watch(
    [currentPage, sizePerPage, sortBy, isSortDirDesc, searchTextFilter],
    () => {
      refetchData()
    },
  )

  const clearFilter = () => {
    searchTextFilter.value = null
    statusActiveFilter.value = null
    statusAgencyFilter.value = null
    agencyManagerFilter.value = null

    refetchData()
  }

  // *===============================================---*
  // *--------- DISPATCH STORE ---------------------------------------*
  // *===============================================---*

  // const updateAgencies = (agenciesData, dataToUpdate, callback) => {
  //   store
  //     .dispatch('app-agencies/updateAgencies', { id: agenciesData.id, dataToUpdate })
  //     .then(() => {
  //       callback()
  //     }).then(() => {
  //       toastSuccess({
  //         title: 'messagesList.success',
  //         content: 'messagesList.agency.edited',
  //       })
  //     })
  //     .catch(e => {
  //       console.error(e)
  //       toastError({
  //         title: 'messagesList.error',
  //         content: `${e.message || e}`,
  //       })
  //     })
  // }

  const updateAgencies = (agenciesData, dataToUpdate, showToast = true) => new Promise((resolve, reject) => {
    store
      .dispatch('app-agencies/updateAgencies', {
        id: agenciesData.id,
        dataToUpdate,
      })
      .then(() => {
        if (showToast) {
          toastSuccess({
            title: 'messagesList.success',
            content: 'messagesList.agency.edited',
          })
        }
        resolve()
      })
      .catch(e => {
        console.error(e)
        toastError({
          title: 'messagesList.error',
          content: `${e.message || e}`,
        })
        reject(e)
      })
  })

  const getAgenciesById = id => new Promise((resolve, reject) => {
    loading.value = true
    store
      .dispatch('app-agencies/getAgenciesById', { id })
      .then(res => {
        resolve(res)
      })
      .catch(e => {
        toastError({
          title: 'messagesList.error',
          content: `${e.message || e}`,
        })
        reject(e)
      })
      .finally(() => {
        loading.value = false
      })
  })

  const addAgencies = agenciesData => new Promise((resolve, reject) => store
    .dispatch('app-agencies/addAgencies', agenciesData)
    .then(res => {
      resolve(res)
      toastSuccess({
        title: 'messagesList.success',
        content: 'messagesList.agency.added',
      })
    })
    .catch(e => {
      reject(e)
      toastError({
        title: 'messagesList.error',
        content: `${e.message || e}`,
      })
    }))

  const { route } = useRouter()

  watch(() => route.value.path, newValue => {
    const paths = newValue.split('/')
    isWarningOverPercentage.value = paths[paths.length - 1] === 'warning_over_percentage'
    refetchData()
  },
  {
    // immediate: true, // FIXME - lỗi null refesh()
  })

  const fetchAgencies = (ctx, callback) => {
    loading.value = true
    const payload = {
      size: sizePerPage.value,
      page: currentPage.value,
      searchText: searchTextFilter.value || '',
      isActive: searchTextFilter.value ? undefined : statusActiveFilter.value,
      status: statusAgencyFilter.value,
      agencyManagerId: agencyManagerFilter.value,
      sortBy: sortBy.value ? sortBy.value : 'createdAt',
      sortDirection: isSortDirDesc.value ? 'DESC' : 'ASC',
      path: isWarningOverPercentage.value ? '/warning/over-percentage' : '',
    }
    store
      .dispatch('app-agencies/fetchAgencies', payload)
      .then(response => {
        totalAgencies.value = response.data.total
        const agencies = response.data
        idsPage.value = response.data.items.map(item => item.id)
        callback(agencies.items.sort((a, b) => b.id - a.id))
        loading.value = false
      })
      .catch(e => {
        toastError({
          title: 'messagesList.error',
          content: `${e.message || e}`,
        })
        loading.value = false
      })
  }

  const fetchListRegister = (ctx, callback) => {
    loading.value = true
    store
      .dispatch('app-agencies/fetchListReg', {
        size: sizePerPage.value,
        page: currentPage.value,
        searchText: searchTextFilter.value || '',
        isActive: statusActiveFilter.value,
        status: statusAgencyFilter.value,
        agencyManagerId: agencyManagerFilter.value,
        sortBy: sortBy.value ? sortBy.value : 'createdAt',
        sortDirection: isSortDirDesc.value ? 'DESC' : 'ASC',
        path: isWarningOverPercentage.value ? '/warning/over-percentage' : '',
      })
      .then(response => {
        // console.log(response)
        totalListRegister.value = response.data.total
        const data = response.data
        // console.log(data)
        idsPage.value = response.data.items.map(item => item.id)
        callback(data.items)
        loading.value = false
      })
      .catch(e => {
        toastError({
          title: 'messagesList.error',
          content: `${e.message || e}`,
        })
        loading.value = false
      })
  }

  const bulkDeleteAgencies = ids => {
    store
      .dispatch('app-agencies/bulkDeleteAgencies', { ids })
      .then(() => {
        refetchData()
        toastSuccess({
          title: 'messagesList.success',
          content: 'messagesList.agency.deleted',
        })
      })
      .catch(e => {
        toastError({
          title: 'messagesList.error',
          content: `${e.message || e}`,
        })
      })
  }

  const bulkDeactiveAgencies = ids => {
    store
      .dispatch('app-agencies/bulkDeactiveAgencies', { ids })
      .then(() => {
        refetchData()
        toastSuccess({
          title: 'messagesList.success',
          content: 'messagesList.agency.deactived',
        })
      })
      .catch(e => {
        toastError({
          title: 'messagesList.error',
          content: `${e.message || e}`,
        })
      })
  }

  const updateActive = agency => {
    store
      .dispatch('app-agencies/updateActive', agency)
      .then(() => {
        refetchData()
        toastSuccess({
          title: 'messagesList.success',
          content: 'messagesList.agency.actived',
        })
      })
      .catch(e => {
        toastError({
          title: 'messagesList.error',
          content: `${e.message || e}`,
        })
      })
  }

  const updateDeactive = agency => {
    store
      .dispatch('app-agencies/updateDeactive', agency)
      .then(() => {
        refetchData()
        toastSuccess({
          title: 'messagesList.success',
          content: 'messagesList.agency.deactived',
        })
      })
      .catch(e => {
        toastError({
          title: 'messagesList.error',
          content: `${e.message || e}`,
        })
      })
  }

  const exportAgencies = locale => {
    const timeZoneOffset = new Date().getTimezoneOffset()
    store
      .dispatch('app-agencies/exportAgencies', { language: locale, timeZoneOffset })
      .then(() => {
        toastSuccess({
          title: 'messagesList.success',
          content: 'messagesList.agency.exported',
        })
      })
      .catch(e => {
        toastError({
          title: 'messagesList.error',
          content: `${e.message || e}`,
        })
      })
  }

  // Sample file csv
  const exportAgenciesTemplateCsv = () => {
    store
      .dispatch('app-agencies/exportAgenciesTemplate')
      .then(() => {
        toastSuccess({
          title: 'messagesList.success',
          content: 'messagesList.agency.exported',
        })
      })
      .catch(e => {
        toastError({
          title: 'messagesList.error',
          content: `${e.message || e}`,
        })
      })
  }

  const importAgencies = arrayList => new Promise((resolve, reject) => {
    store
      .dispatch('app-agencies/importAgencies', { agencies: arrayList })
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })

  // fetchAgencyManagerFilter
  const agenciesManagerList = ref([])
  const agenciesManagerListDefault = ref([])

  const fetchAgenciesManagerByFilter = async (search = '') => {
    agenciesManagerList.value = []
    loading.value = true
    try {
      const data = await api.employee.fetchEmployeesByFilter({
        types: ['BE', 'SE', 'KTV', 'KTT'],
        searchText: search,
      })
      if (data && !search) {
        agenciesManagerListDefault.value = data.items.filter(
          emp => emp.isActive === true,
        )
      }
      agenciesManagerList.value = data.items.filter(
        emp => emp.isActive === true,
      )
    } catch (error) {
      console.error(error)
    } finally {
      loading.value = false
    }
  }

  const openAgenciesManager = () => {
    if (agenciesManagerListDefault.value.length === 0) {
      fetchAgenciesManagerByFilter()
    } else {
      agenciesManagerList.value = agenciesManagerListDefault.value
    }
  }

  const searchAgenciesManager = debounce(search => {
    if (search) fetchAgenciesManagerByFilter(search)
  }, 500)

  function changeNote(id, payload) {
    return new Promise((resolve, reject) => {
      store
        .dispatch('app-agencies/changeNote', { id, payload })
        .then(res => {
          resolve(res)
          toastSuccess({
            title: 'messagesList.success',
            content: 'messagesList.agency.edited',
          })
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: `${error.message || error}`,
          })
        })
    })
  }

  function changeSocialNetwork(id, payload) {
    return new Promise((resolve, reject) => {
      store
        .dispatch('app-agencies/changeSocialNetwork', { id, payload })
        .then(res => {
          resolve(res)
          toastSuccess({
            title: 'messagesList.success',
            content: 'messagesList.agency.edited',
          })
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: `${error.message || error}`,
          })
        })
    })
  }

  function updateHideFeeConfig(id) {
    return new Promise((resolve, reject) => {
      store
        .dispatch('app-agencies/updateHideFeeConfig', { id })
        .then(res => {
          resolve(res)
          toastSuccess({
            title: 'messagesList.success',
            content: 'messagesList.agency.edited',
          })
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: `${error.message || error}`,
          })
        })
    })
  }

  function updateAgenciesFeeConfig(id) {
    return new Promise((resolve, reject) => {
      store
        .dispatch('app-agencies/updateAgenciesFeeConfig', { id })
        .then(res => {
          resolve(res)
          toastSuccess({
            title: 'messagesList.success',
            content: 'messagesList.agency.edited',
          })
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: `${error.message || error}`,
          })
        })
    })
  }

  function SetLowFareTracking(id) {
    return new Promise((resolve, reject) => {
      store
        .dispatch('app-agencies/SetLowFareTracking', { id })
        .then(res => {
          resolve(res)
          toastSuccess({
            title: 'messagesList.success',
            content: 'messagesList.agency.edited',
          })
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: `${error.message || error}`,
          })
        })
    })
  }

  function updateSendMailAirline(id, payload) {
    return new Promise((resolve, reject) => {
      store
        .dispatch('app-agencies/updateSendMailAirline', { id, payload })
        .then(res => {
          resolve(res)
          toastSuccess({
            title: 'messagesList.success',
            content: 'messagesList.agency.edited',
          })
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: `${error.message || error}`,
          })
        })
    })
  }

  function updateSendPhoneAirline(id, payload) {
    return new Promise((resolve, reject) => {
      store
        .dispatch('app-agencies/updateSendPhoneAirline', { id, payload })
        .then(res => {
          resolve(res)
          toastSuccess({
            title: 'messagesList.success',
            content: 'messagesList.agency.edited',
          })
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: `${error.message || error}`,
          })
        })
    })
  }

  function updateEnableQuickName(id) {
    return new Promise((resolve, reject) => {
      store
        .dispatch('app-agencies/updateEnableQuickName', { id })
        .then(res => {
          resolve(res)
          toastSuccess({
            title: 'messagesList.success',
            content: 'messagesList.agency.edited',
          })
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: `${error.message || error}`,
          })
        })
    })
  }

  function updateEnableRebook(id) {
    return new Promise((resolve, reject) => {
      store
        .dispatch('app-agencies/updateEnableRebook', { id })
        .then(res => {
          resolve(res)
          toastSuccess({
            title: 'messagesList.success',
            content: 'messagesList.agency.edited',
          })
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: `${error.message || error}`,
          })
        })
    })
  }

  function updateEnableRetail(id) {
    return new Promise((resolve, reject) => {
      loading.value = true
      store
        .dispatch('app-agencies/updateEnableRetail', { id })
        .then(res => {
          refetchData()
          toastSuccess({
            title: 'messagesList.success',
            content: 'messagesList.agency.edited',
          })
          resolve(res)
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: `${error.message || error}`,
          })
        })
        .finally(() => { loading.value = false })
    })
  }

  function updateEnableInvoice(id) {
    return new Promise((resolve, reject) => {
      loading.value = true
      store
        .dispatch('app-agencies/updateEnableInvoice', { id })
        .then(res => {
          refetchData()
          toastSuccess({
            title: 'messagesList.success',
            content: 'messagesList.agency.edited',
          })
          resolve(res)
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: `${error.message || error}`,
          })
        })
        .finally(() => { loading.value = false })
    })
  }

  function updateEnableInvoiceRequest(id) {
    return new Promise((resolve, reject) => {
      loading.value = true
      store
        .dispatch('app-agencies/updateEnableInvoiceRequest', { id })
        .then(res => {
          refetchData()
          toastSuccess({
            title: 'messagesList.success',
            content: 'messagesList.agency.edited',
          })
          resolve(res)
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: `${error.message || error}`,
          })
        })
        .finally(() => { loading.value = false })
    })
  }

  function updateEnableTour(id) {
    return new Promise((resolve, reject) => {
      loading.value = true
      store
        .dispatch('app-agencies/updateEnableTour', { id })
        .then(res => {
          refetchData()
          toastSuccess({
            title: 'messagesList.success',
            content: 'messagesList.agency.edited',
          })
          resolve(res)
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: `${error.message || error}`,
          })
        })
        .finally(() => { loading.value = false })
    })
  }

  function updateEnableTrain(id) {
    return new Promise((resolve, reject) => {
      loading.value = true
      store
        .dispatch('app-agencies/updateEnableTrain', { id })
        .then(res => {
          refetchData()
          toastSuccess({
            title: 'messagesList.success',
            content: 'messagesList.agency.edited',
          })
          resolve(res)
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: `${error.message || error}`,
          })
        })
        .finally(() => { loading.value = false })
    })
  }

  function updateEnableRenameOnMarketPlace(id) {
    return new Promise((resolve, reject) => {
      loading.value = true
      store
        .dispatch('app-agencies/updateEnableRenameOnMarketPlace', { id })
        .then(res => {
          refetchData()
          toastSuccess({
            title: 'messagesList.success',
            content: 'messagesList.agency.edited',
          })
          resolve(res)
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: `${error.message || error}`,
          })
        })
        .finally(() => { loading.value = false })
    })
  }
  function updateLimitTimeSearch(id, payload) {
    return new Promise((resolve, reject) => {
      store
        .dispatch('app-agencies/updateLimitTimeSearch', { id, payload })
        .then(res => {
          resolve(res)
          toastSuccess({
            title: 'messagesList.success',
            content: 'messagesList.agency.edited',
          })
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: `${error.message || error}`,
          })
        })
    })
  }

  function updateLimitPaxBooking(id, payload) {
    return new Promise((resolve, reject) => {
      store
        .dispatch('app-agencies/updateLimitPaxBooking', { id, payload })
        .then(res => {
          resolve(res)
          toastSuccess({
            title: 'messagesList.success',
            content: 'messagesList.agency.edited',
          })
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: `${error.message || error}`,
          })
        })
    })
  }

  function resetBalance(id, payload) {
    return new Promise((resolve, reject) => {
      store.dispatch('app-agencies/resetBalance', { id, payload })
        .then(res => {
          resolve(res)
          toastSuccess({
            title: 'messagesList.success',
            content: 'agency.editBalance.msgEditSuccess',
          })
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: 'agency.editBalance.msgEditError',
          })
        })
    })
  }

  function enableBookingGroup(id) {
    return new Promise((resolve, reject) => {
      store.dispatch('app-agencies/enableBookingGroup', id)
        .then(res => {
          resolve(res)
          toastSuccess({
            title: 'messagesList.success',
            content: 'messagesList.agency.edited',
          })
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: `${error.message || error}`,
          })
        })
    })
  }

  function enableSendEmployeeMailAirline(id) {
    return new Promise((resolve, reject) => {
      store.dispatch('app-agencies/enableSendEmployeeMailAirline', id)
        .then(res => {
          resolve(res)
          toastSuccess({
            title: 'messagesList.success',
            content: 'messagesList.agency.edited',
          })
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: `${error.message || error}`,
          })
        })
    })
  }

  // company
  function createCompany(id, payload) {
    return new Promise((resolve, reject) => {
      loading.value = true
      store
        .dispatch('app-agencies/createCompany', { id, payload })
        .then(res => {
          resolve(res)
          toastSuccess({
            title: 'messagesList.success',
            content: 'messagesList.agency.edited',
          })
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: `${error.message || error}`,
          })
        })
        .finally(() => {
          loading.value = false
        })
    })
  }

  function updateCompany(id, payload) {
    return new Promise((resolve, reject) => {
      loading.value = true
      store
        .dispatch('app-agencies/updateCompany', { id, payload })
        .then(res => {
          resolve(res)
          toastSuccess({
            title: 'messagesList.success',
            content: 'messagesList.agency.edited',
          })
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: `${error.message || error}`,
          })
        })
        .finally(() => {
          loading.value = false
        })
    })
  }

  function toggleActiveCompany(companyId, payload) {
    return new Promise((resolve, reject) => {
      loading.value = true
      store
        .dispatch('app-agencies/toggleActiveCompany', { id: companyId, payload })
        .then(res => {
          resolve(res)
          toastSuccess({
            title: 'messagesList.success',
            content: 'messagesList.agency.edited',
          })
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: `${error.message || error}`,
          })
        })
        .finally(() => {
          loading.value = false
        })
    })
  }

  // web config
  function createWebConfig(id, payload) {
    return new Promise((resolve, reject) => {
      loading.value = true
      store
        .dispatch('app-agencies/createWebConfig', { id, payload })
        .then(res => {
          resolve(res)
          toastSuccess({
            title: 'messagesList.success',
            content: 'messagesList.agency.edited',
          })
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: `${error.message || error}`,
          })
        })
        .finally(() => {
          loading.value = false
        })
    })
  }

  function updateWebConfig(id, payload) {
    return new Promise((resolve, reject) => {
      loading.value = true
      store
        .dispatch('app-agencies/updateWebConfig', { id, payload })
        .then(res => {
          resolve(res)
          toastSuccess({
            title: 'messagesList.success',
            content: 'messagesList.agency.edited',
          })
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: `${error.message || error}`,
          })
        })
        .finally(() => {
          loading.value = false
        })
    })
  }
  function sendEmailTest(payload) {
    return new Promise((resolve, reject) => {
      loading.value = true
      store
        .dispatch('app-agencies/sendEmailTest', { payload })
        .then(res => {
          resolve(res)
          toastSuccess({
            title: 'messagesList.success',
            content: 'messagesList.agency.sendSuccess',
          })
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: `${error.message || error}`,
          })
        })
        .finally(() => {
          loading.value = false
        })
    })
  }

  return {
    fetchAgencies,
    fetchListRegister,
    getAgenciesById,
    addAgencies,
    updateAgencies,
    bulkDeleteAgencies,
    bulkDeactiveAgencies,
    updateActive,
    updateDeactive,
    tableColumns,
    tableColumnsReg,
    tableEmployees,
    sizePerPage,
    currentPage,
    totalAgencies,
    totalListRegister,
    idsPage,
    dataMeta,
    dataMetaRegister,
    sizePerPageOptions,
    sortBy,
    isSortDirDesc,
    refAgenciesListTable,
    refRegListTable,
    refetchDataReg,
    refetchData,
    clearFilter,

    // Extra Filters
    searchTextFilter,
    statusActiveFilter,
    statusAgencyFilter,
    agencyManagerFilter,

    emailDuplicate,
    validateEmail,

    // Loading
    loading,
    exportAgencies,
    importAgencies,
    exportAgenciesTemplateCsv,

    isEmptyFilter,

    // fetch Agency Manager
    agenciesManagerList,
    searchAgenciesManager,
    openAgenciesManager,

    updateHideFeeConfig,
    SetLowFareTracking,
    updateAgenciesFeeConfig,
    updateSendMailAirline,
    updateSendPhoneAirline,
    changeNote,
    changeSocialNetwork,
    resetBalance,

    enableBookingGroup,
    enableSendEmployeeMailAirline,
    updateEnableQuickName,
    updateEnableRebook,
    updateEnableRetail,
    updateEnableInvoice,
    updateEnableInvoiceRequest,
    updateEnableTour,
    updateEnableTrain,
    updateEnableRenameOnMarketPlace,
    updateLimitPaxBooking,
    updateLimitTimeSearch,
    MAX_SIZE_TO_UPLOAD_FILE: process.env.VUE_APP_MAX_SIZE_FOR_UPLOAD_FILE,
    fileCanUpload: '.pdf, .docx, .doc, .txt, .json',
    createCompany,
    updateCompany,
    toggleActiveCompany,
    createWebConfig,
    updateWebConfig,
    sendEmailTest,
  }
}

export function shortenFileName(url) {
  const result = url.split('/').pop().split('_')
  return result.length > 1 ? result.splice(1).join('') : result[0]
}
